@import '@angular/material/theming';
@include mat-core();

$casa-primary-palette: (
  50 : #e5eff9,
  100 : #bfd6f0,
  200 : #94bbe6,
  300 : #69a0db,
  400 : #488bd4,
  500 : #2877cc,
  600 : #246fc7,
  700 : #1e64c0,
  800 : #185ab9,
  900 : #0f47ad,
  A100 : #dce7ff,
  A200 : #a9c4ff,
  A400 : #76a1ff,
  A700 : #5d8fff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$casa-accent-palette: (
  50 : #ffede1,
  100 : #ffd1b3,
  200 : #ffb380,
  300 : #ff954d,
  400 : #ff7e27,
  500 : #ff6701,
  600 : #ff5f01,
  700 : #ff5401,
  800 : #ff4a01,
  900 : #ff3900,
  A100 : #ffffff,
  A200 : #fff4f2,
  A400 : #ffcabf,
  A700 : #ffb4a6,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$casa-warm-palette: (
  50 : #fde9e9,
  100 : #f9c8c8,
  200 : #f6a3a3,
  300 : #f27e7e,
  400 : #ef6262,
  500 : #ec4646,
  600 : #ea3f3f,
  700 : #e73737,
  800 : #e42f2f,
  900 : #df2020,
  A100 : #ffffff,
  A200 : #ffe3e3,
  A400 : #ffb0b0,
  A700 : #ff9696,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

$my-app-primary: mat-palette($casa-primary-palette);
$my-app-accent:  mat-palette($casa-accent-palette);
$my-app-warn:    mat-palette($casa-warm-palette);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);

@mixin custom-theme($theme) {
  @include mat-button-theme($theme);
  @include mat-button-toggle-theme($theme);
  @include mat-card-theme($theme);
}

.mat-mdc-form-field {
  width: 100%;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}
.mat-mdc-form-field-focus-overlay {
  background-color: white;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before{
  border-bottom-color: #dedede;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #dedede;
}
